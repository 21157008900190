<template>
  <footer class="footer">
    <div class="inner">
      <page-link class="logo" to="/">
        <img alt="Bassetlaw Triathlon Club" src="../assets/logo.png" />
      </page-link>
      <div class="right">
        <div class="social" v-if="settings">
          <a aria-label="Facebook" :href="settings.facebook" rel="noreferrer" target="_blank" v-if="settings.facebook">
            <font-awesome-icon :icon="['fab', 'facebook']" class="fb" />
          </a>
          <a
            aria-label="Instagram"
            :href="settings.instagram"
            rel="noreferrer"
            target="_blank"
            v-if="settings.instagram"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" class="in" />
          </a>
          <a aria-label="Email" :href="'mailto:' + settings.email" v-if="settings.email">
            <font-awesome-icon class="email" icon="envelope" />
          </a>
        </div>
        <div class="links">
          <ul>
            <li :key="menuPage.ItemID" v-for="menuPage in menuPages">
              <page-link :href="menuPage.url">{{ menuPage.name }}</page-link>
            </li>
            <li>
              <a :href="settings.sign_up_url" rel="noreferrer" target="_blank">Join Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="legal">
        &copy; Bassetlaw Triathlon Club |
        <page-link :to="privacyPage.url" v-if="privacyPage">{{ privacyPage.name }}</page-link
        ><br />Website designed &amp; developed by
        <a href="https://chameleoncreative.marketing/" rel="noreferrer" target="_blank">Chameleon Creative</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.settings
    },
    menuPages() {
      return this.$store.state.menu_pages
    },
    privacyPage() {
      return this.$store.state.privacyPage
    },
  },
}
</script>

<style scoped>
.footer {
  padding: 8rem 0;
  padding-bottom: 3rem;
  background: rgba(240, 240, 240, 1);
}
.logo {
  max-width: 25%;
  display: inline-block;
}
.right {
  float: right;
}
.social {
  font-size: 4rem;
  float: right;
}
.social a {
  margin-left: 1rem;
}
.in {
  color: rgb(151, 10, 151);
}
.fb {
  color: #3b579d;
}
.email {
  color: #ed2229;
}
.legal {
  text-align: center;
  padding-top: 5rem;
  font-size: 1.2rem;
  clear: both;
}
.links {
  float: right;
  columns: auto 2;
  column-gap: 8rem;
  margin-right: 5rem;
  border-right: 5px solid #ed2229;
  padding-right: 8rem;
}
.links ul,
.links li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  color: #3b579d;
}
.links li {
  padding: 0.7rem 0;
}
@media screen and (max-width: 1100px) {
  .logo {
    display: block;
    margin: auto;
    min-width: 20rem;
  }
  .right {
    float: none;
    text-align: center;
  }
  .links {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
    float: none;
    column-gap: 0;
  }
  .social {
    float: none;
    padding: 5rem 0;
  }
}
</style>
